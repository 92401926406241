import React, { useEffect, useState } from 'react';
import AdminContainer from '../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../contexts/breadcrumbs-provider';
import Loadable from '../../../data/loadable';
import Heading from '../../../layout/admin/heading';
import CenterNotifications from './center-notifications';
import SEO from '../../../layout/seo';

const CenterAdminDashboard = () => {
  const [loading, setLoading] = useState(true);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setLoading(true);
    setBreadcrumbs([{
      href: '/app/center-admin/settings',
      label: 'Settings',
    }]);
    setLoading(false);

    // const init = async () => {
    //   try {
    //     const result = await getApiRequest(`/admin/stats/dashboard`);
    //     setStats(result);
    //   } catch (err) {
    //     console.log(err, 'error loading stats');
    //   }
    //   setLoading(false);
    // }
    
    // init();
  }, []);

  return (
    <AdminContainer centerAdmin>
      <SEO title="Settings" />
      <Heading title="Settings" />
      <Loadable loading={loading}>
        <div className="max-w-3xl p-4 md:px-8 mx-auto">
          <CenterNotifications />
        </div>
      </Loadable>
    </AdminContainer>
  );
}

export default CenterAdminDashboard;
