import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { PhoneIcon, MailIcon } from '@heroicons/react/outline';
import { useNotifications } from '../../../../contexts/notifications-provider';
import { getApiRequest, postApiRequest } from '../../../../utils/request';
import { getCenterAdminToken } from '../../../../utils/auth';
import Loadable from '../../../data/loadable';
import { validatePhone, formatPhoneFromServer } from '../../../../utils/helpers';
import Confirm from '../../../overlays/confirm';
import FormModal from '../../../overlays/form-modal';
import AddButton from '../../../buttons/add-button';


export default function CenterNotifications() {
  const [loading, setLoading] = useState(true);
  const [centerNotifications, setCenterNotifications] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [toRemove, setToRemove] = useState(null);
  const { addNotification } = useNotifications();


  const loadCenterNotifications = async () => {
    setLoading(true);
    try {
      const fetchedNotifications = await getApiRequest(`/center-admin/notifications?password=${getCenterAdminToken()}`);
      setCenterNotifications(fetchedNotifications);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        return;
      }
      console.log(err, 'error loading center');
      addNotification({
        type: 'error',
        body: 'There was an error loading notification addresses.',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadCenterNotifications();
    
  }, []);

  const addForm = {
    noContainer: true,
    type: 'post',
    postRoute: `/center-admin/notification`,
    beforePost: (values) => {
      return {
        ...values,
        password: getCenterAdminToken(),
      };
    },
    successNotification: 'The center notification was created.',
    successHandler: (values, actions) => {
      actions.resetForm();
      setShowAddModal(false);
      loadCenterNotifications();
    },
    fields: [
      {
        name: 'type',
        label: 'Notification Type',
        type: 'select',
        options: [
          {
            value: 'email',
            label: 'Email',
          },
          {
            value: 'phone',
            label: 'Phone',
          },
        ],
        initialValue: () => {
          return 'email';
        },
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        showIf: (formik) => {
          return formik.values.type === 'email';
        },
        validations: () => {
          return Yup.string().when('type', {
            is: 'email',
            then: Yup.string().required('Email is a required field.').email('Invalid email address.'),
          });
        },
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'phone',
        helpText: 'Must be a mobile phone.',
        showIf: (formik) => {
          return formik.values.type === 'phone';
        },
        validations: () => {
          return Yup.string().when('type', {
            is: 'phone',
            then: Yup.string().required('You must provide a phone number.')
              .test(
                'valid-phone',
                'Must be a valid phone number.',
                (val) => val === '' || typeof val === 'undefined' || validatePhone(val)
              ),
          });
        },
      },
    ],
    submitLabel: `Add Center Notification`,
  };

  const confirmCenterNotificationRemoval = (notification) => {
    setToRemove(notification);
    setShowConfirm(true);
  };

  const cancelCenterNotificationRemoval = () => {
    setShowConfirm(false);
    setToRemove(null);
  }

  const removeCenterNotification = async () => {
    setShowConfirm(false);
    if (!toRemove) {
      return;
    }
    // setLoading(true);
    try {
      await postApiRequest(`/center-admin/notifications/${toRemove.id}/delete`, { password: getCenterAdminToken() });
      await loadCenterNotifications();
      addNotification({
        type: 'success',
        body: 'The notification address was removed.',
        timeout: 3000,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        return;
      }
      addNotification({
        type: 'error',
        body: 'There was an error removing the notification.',
      });
      // setLoading(false);
    }
  }

  const getConfirmationBody = () => {
    if (!toRemove) {
      return 'Are you sure you want to remove the item? Updates will no longer be sent when center events happen.';
    }
    const { type, email, phone } = toRemove;
    const formatted = type === 'phone' ? formatPhoneFromServer(phone) : email;
    return `Are you sure you want to remove ${formatted}? Updates will no longer be sent when center events happen.`;
  }

  return (
    <div className="bg-white shadow rounded-lg px-4 py-5 sm:p-6">
      <h3 className="font-medium text-gray-900">Notification Addresses</h3>
      <p className="text-gray-700 py-4 text-sm">Add addresses that will be notified when events happen, such as new registrations.</p>
      <Loadable loading={loading}>
        <ul role="list" className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
          {!!centerNotifications && !!centerNotifications.length && centerNotifications.map((notification) => {
            const { type, phone, email, id } = notification;
            const formatted = type === 'phone' ? formatPhoneFromServer(phone) : email;
            return (
              <li key={id} className="py-3 flex justify-between items-center">
                <div className="flex items-center">
                  {type === 'email' && <MailIcon className="w-8 h-8 text-gray-400" aria-hidden="true" />}
                  {type === 'phone' && <PhoneIcon className="w-8 h-8 text-gray-400" aria-hidden="true" />}
                  <p className="ml-4 text-sm font-medium text-gray-900">{formatted}</p>
                </div>
                <button
                  onClick={() => confirmCenterNotificationRemoval(notification)}
                  type="button"
                  className="ml-6 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Remove<span className="sr-only"> {formatted}</span>
                </button>
              </li>
            );
          })}
          <li className="py-2 flex justify-between items-center">
            <AddButton onClick={() => setShowAddModal(true)} buttonText="Add Notification Address" />
          </li>
        </ul>
      </Loadable>
      <Confirm
        open={showConfirm}
        action={removeCenterNotification}
        cancel={cancelCenterNotificationRemoval}
        actionText="Remove"
        title="Remove Notification Address"
        body={getConfirmationBody()}
      />
      <FormModal
        open={showAddModal}
        form={addForm}
        cancel={() => setShowAddModal(false)}
        title="Add Notification Address"
        body="Add a new phone number or email to receive notifications when center events occur."
      />
    </div>
  );
}
